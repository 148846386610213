import React from 'react';
import Navbar from "../components/navbar"
import "../styles/styles.scss"
import "normalize.css"
import Hero from '../components/hero';
import Highlights from '../components/highlights';
import Treatments from '../components/treatments';
import About from "../components/about";
import Faq from '../components/faq';
import Contact from '../components/contact';
import Footer from '../components/footer';
import CalendlyWidget from '../components/calendly-widget';
import SEO from '../components/seo';
import { useStaticQuery, graphql } from "gatsby"

export function Index() {
  const data = useStaticQuery(graphql`
  query {
    strapiHomepage {
      about {
        body {
          data {
            body
          }
        }
        image {
          alternativeText
          caption
          url
        }
        title
      }

      faqs {
        body {
          data {
            body
          }
        }
        faq {
          question
          answer {
            data {
              answer
            }
          }
          strapi_id
          id
        }
        strapi_id
        id
        title
      }
      hero {
        body {
          data {
            body
          }
        }
        title
        subtitle
        image {
          alternativeText
          caption
          url
        }
      }
      seo {
        metaDescription
        metaTitle
      }
      treatments {
        title
        body {
          data {
            body
          }
        }
      }
      usps {
        title
        body { data { body } }
        usp {
          title
          strapi_id
          id
          image {
            alternativeText
            caption
            url
          }
        }
      }
    }
  }
  `)
  return (
    <>
      <SEO title={data.strapiHomepage.seo.metaTitle} description={data.strapiHomepage.seo.metaDescription} />
      <Navbar></Navbar>
      <Hero content={data.strapiHomepage.hero} ></Hero>
      <Highlights content={data.strapiHomepage.usps}></Highlights>
      <Treatments content={data.strapiHomepage.treatments} sliceRange={3} homepage={true} ></Treatments>
      <About content={data.strapiHomepage.about}></About>
      <Faq faqTitle={data.strapiHomepage.faqs.title} faqs={data.strapiHomepage.faqs.faq}></Faq>
      <Contact></Contact>
      <Footer></Footer>
      <CalendlyWidget></CalendlyWidget>
    </>
  );
}

export default Index;
