import Container from "react-bootstrap/esm/Container";
import { ReactMarkdown } from "react-markdown/lib/react-markdown";

/* eslint-disable-next-line */
export interface HighlightsProps { }

export function Highlights({ content }) {
  return (
    <Container style={{ marginTop: 100 }}>
      <div className="highlights-content">
        <h2>
          {content.title}
        </h2>
        <ReactMarkdown>{content.body.data.body}</ReactMarkdown>
      </div>
      <div className="highlights-features">
        {content.usp.map((usp) => (
          <div className="highlights-feature" key={usp.strapi_id}>
            <img src={usp.image.url} alt={usp.image.alternativeText} />
            <h3>{usp.title}</h3>
          </div>
        ))}
      </div>
    </Container>
  );
}

export default Highlights;
