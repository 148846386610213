import * as React from "react"
import { useEffect, useState } from "react";
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import "../styles/styles.scss"
import Header from "./navbar";
import Footer from "./footer";
import UpArrow from "../images/icons/chevron-up.png"
import CalendlyWidget from "./calendly-widget";

const Layout = ({ children }) => {
  // const data = useStaticQuery(graphql`
  //    query SiteTitleQuery {
  //      site {
  //        siteMetadata {
  //          title
  //        }
  //      }
  //    }
  //  `)
  // const [showButton, setShowButton] = useState(false);
  // useEffect(() => {
  //   window.addEventListener("scroll", () => {
  //     if (window.pageYOffset > 300) {
  //       setShowButton(true);
  //     } else {
  //       setShowButton(false);
  //     }
  //   });
  // }, []);

  // // This function will scroll the window to the top 
  // const scrollToTop = () => {
  //   window.scrollTo({
  //     top: 0,
  //     behavior: 'smooth' // for smoothly scrolling
  //   });
  // };

  return (
    <>
      <Header></Header>
      <main>{children}</main>
      {/* {showButton && (
        <button onClick={scrollToTop} className="back-to-top">
          <img src={UpArrow} alt="^" width={18} height={18} />
        </button>
      )} */}
      <CalendlyWidget />
      <Footer></Footer>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
