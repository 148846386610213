import React from 'react'
import Faq from '../components/faq'
import Layout from '../components/layout'
import ReactMarkDown from 'react-markdown'
import Container from 'react-bootstrap/esm/Container'
import CalendlyButton from '../components/calendly-popup'

const Treatment = (props: any) => {
    const nodes = props.pageContext
    const {
        images,
        faqs,
        body,
        faqTitle,
        price,
        name
    } = nodes
    return (
        <Layout>
            <section>
                <Container>
                    <div className='row-50'>
                        <div className='image'>
                            <img src={images[0].url} alt={images[0].alternativeText} title={images[0].caption} style={{ maxWidth: '100%' }} />
                        </div>
                        <div className='body'>
                            <h1>{name}</h1>
                            <ReactMarkDown>
                                {body.data.body}
                            </ReactMarkDown>
                            <div className='price-and-book'>
                                <h3>€ {price}</h3>
                                <div style={{ marginTop: 24 }}>
                                    <CalendlyButton title='Afspraak Maken'></CalendlyButton>
                                </div>
                            </div>
                        </div>
                    </div>
                </Container>
            </section>
            <section>
                <Container>
                    <Faq faqs={faqs} faqTitle={faqTitle} />
                </Container>
            </section>
        </Layout>
    )
}

export default Treatment