import React from "react";
import { Link } from "gatsby";
import Container from "react-bootstrap/esm/Container";
import Logo from "../images/logo.png"
import Facebook from "../images/Facebook.svg";
import LinkedIn from "../images/Linkeidn.svg";
import Instagram from "../images/Instagram.svg";
import Twitter from "../images/Twitter.svg";
/* eslint-disable-next-line */
export interface FooterProps { }

export function Footer(props: FooterProps) {
  return (
    <footer>
      <Container>
        <img src={Logo} alt='logo Mondzorg Sarphaat'></img>
        <div className="footer-content">
          <div className='footer-content__left'>
            <p>"Wij verzorgen niet alleen jouw mond, maar ook jou."</p>
          </div>
          <div className='footer-content__right'>
            <p>"Wij behandelen jou alsof je naar de SPA gaat. Relaxed en ontspannen loop je naar buiten met een stralend gebit. Geloof je het niet? Probeer het zelf..."</p>
          </div>
          <div className='footer-content__center'>
            <Link to="/behandelingen">Behandelingen</Link> <br></br>
            <Link to="/de-praktijk">De Praktijk</Link> <br></br>
            <Link to="/locatie-en-contact">Locatie & Contact</Link> <br></br>
          </div>
    
        </div>
        <div className="footer-content__bottom">
          {/* <img src={Facebook} alt='facebook'></img> */}
          <a href="https://www.instagram.com/mondzorg_sarphaat/"><img src={Instagram} alt='Instagram'></img></a>
          <a href="https://www.linkedin.com/in/eline-van-den-berg-a8b621108"><img src={LinkedIn} alt='LinkedIn'></img></a>
          {/* <img src={Twitter} alt='Twitter'></img> */}
          <p>© Mondzorg Sarphaat</p>
        </div>
      </Container>
    </footer>
  );
}

export default Footer;
