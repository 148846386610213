import { Link } from 'gatsby';
import React from 'react';
import Container from 'react-bootstrap/esm/Container';
import Layout from '../components/layout';

const NotFoundPage = () => <Layout>
    <section>
        <Container>
            <div style={{minHeight:'50vh'}}>
            <h1>Pagina niet gevonden</h1>
            <Link to='/'>Keer terug naar home</Link>
            </div>
        </Container>
    </section>
</Layout>;

export default NotFoundPage;
