import Container from 'react-bootstrap/esm/Container';
import TreatmentsBgTop from '../images/treatment-bg-top.webp';
import { ReactMarkdown } from 'react-markdown/lib/react-markdown';
import { Link } from 'gatsby';
import { useStaticQuery, graphql } from 'gatsby';

/* eslint-disable-next-line */
export interface TreatmentsProps {}
// TODO: get Treatments from Collection Type, show max 6
export function Treatments({ content, sliceRange, homepage }) {
  const data = useStaticQuery(graphql`
    query {
      allStrapiTreatment {
        nodes {
          name
          price
          summary
          strapi_id
          images {
            alternativeText
            caption
            url
          }
        }
      }
    }
  `);

  const sliceStart = 0;
  const sliceEnd = sliceRange;
  return (
    <div id="populaire-behandelingen">
      {homepage && (
        <img
          src={TreatmentsBgTop}
          alt="Behandelingen Achtergrond"
          className="img-fluid"
        />
      )}
      <div className="treatment-section">
        <Container>
          <div className="treatment-headline">
            {homepage ? <h2>{content.title}</h2> : <h1>{content.title}</h1>}
            <ReactMarkdown>{content.body.data.body}</ReactMarkdown>
          </div>
          {data.allStrapiTreatment.nodes
            .slice(sliceStart, sliceEnd)
            .map((treatment) => (
              <div className="treatment-wrapper" key={treatment.strapi_id}>
                <div className="treatment-content">
                  <h3>{treatment.name}</h3>
                  <p>{treatment.summary}</p>

                  {treatment.linkText ? (
                    <Link
                      to={`/behandelingen/${treatment.name
                        .replace(' ', '-')
                        .toLowerCase()}`}
                      className="btn-text"
                    >
                      {treatment.linkText}
                    </Link>
                  ) : (
                    <Link
                      to={`/behandelingen/${treatment.name
                        .replace(' ', '-')
                        .toLowerCase()}`}
                      className="btn-text"
                    >{`Meer informatie >>>`}</Link>
                  )}
                </div>
                {treatment.images && (
                  <div className="teatment-image">
                    <img
                      src={treatment.images[0].url}
                      alt={treatment.images[0].alternativeText}
                      title={treatment.images[0].caption}
                      className="img-fluid"
                    />
                  </div>
                )}
              </div>
            ))}
        </Container>
      </div>
      {homepage && (
        <img
          src={TreatmentsBgTop}
          alt="Behandelingen Achtergrond"
          className="img-fluid"
          style={{ transform: 'rotate(180deg)' }}
        />
      )}
    </div>
  );
}

export default Treatments;
