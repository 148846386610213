import React from "react";
import { useEffect, useState } from "react";
import CalendlyButton from "./calendly-popup";

const CalendlyWidget = () => {
  const [showButton, setShowButton] = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.pageYOffset > 800) {
        setShowButton(true);
      } else {
        setShowButton(false);
      }
    });
  });
  return (
    <div className="calendly-widget">
      {showButton &&
        <CalendlyButton></CalendlyButton>
      }
    </div>
  );
};

export default CalendlyWidget;