import React from 'react'
import Layout from '../components/layout'
import { useStaticQuery, graphql } from "gatsby"
import Contact from '../components/contact'
import Faq from '../components/faq'
import Container from 'react-bootstrap/esm/Container'

const LocationAndContact = () => {
    const data = useStaticQuery(graphql`
    query {
      strapiLocation {
        title
        faqs {
            question
            strapi_id
            answer {
                data {
                    answer
                }
            }
        }
        faqTitle
      }
    }
  `)


    return (
        <Layout>
            <Container><Contact></Contact></Container>
            <section style={{ textAlign: 'center', padding: 48, marginTop: 48 }} className='bg-primary-light text-white' >
                <h1>Wij zien je graag bij Mondzorg Sarphaat</h1>
                {/* <h1 >{data.strapiLocation.title}</h1> */}
                <p style={{ fontWeight: 600 }}>Ervaar ontspannen jouw stralende gezonde lach!</p>
            </section>
            <Container>
                <Faq faqTitle={data.strapiLocation.faqTitle} faqs={data.strapiLocation.faqs} />
            </Container>
        </Layout>
    )
}

export default LocationAndContact