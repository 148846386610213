import Container from "react-bootstrap/esm/Container";
import Accordion from "react-bootstrap/Accordion"
import ReactMarkDown from 'react-markdown'

/* eslint-disable-next-line */
export interface FaqProps { 
  faqs: Faq,
  faqTitle: string
}

export interface Faq {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  map(arg0: (item: any) => JSX.Element): import("react").ReactNode;
  question: string;
  answer: string;
  strapi_id: number;
  id: string;
  body?: string
  title?: string
}

export function Faq(props: FaqProps) {
  console.log('FAQS', props.faqs )

  const {
    faqs,
    faqTitle
  } = props

  // const faqs = [
  //   {
  //     question: 'Wat is de mondhygiënist?',
  //     answer: 'Een gediplomeerde professional met een beschermde titel. De mondhygiënist richt zich op de preventie en mondverzorging om zo tandbederf en tandvleesaandoeningen te voorkomen en te bestrijden.',
  //     key: '0'
  //   },
  //   { 
  //     question: 'Voor wie is de mondhygiënist?', 
  //     answer: 'Voor iedereen die graag zijn/haar mond gezond en schoon wil houden. Zowel voor kinderen, volwassenen als voor ouderen die zich bewust zijn dat een gezond gebit van belang is.', 
  //     key: '1' 
  //   },
  //   { 
  //     question: 'Wat is het verschil tussen een mondhygiënist en een geregistreerd mondhygiënist?', 
  //     answer: 'Een geregistreerd mondhygiënist staat in het BIG-register, een mondhygiënist niet. Zij voeren wel dezelfde handelingen uit. BIG staat voor Beroepen in de Individuele Gezondheidszorg. Alle taken mogen dan uitgevoerd worden zonder opdracht of aanwezigheid van een tandarts. ', 
  //     key: '2' }
  //     ,
  //   { 
  //     question: 'Wat is het verschil tussen een tandarts en de mondhygiënist? ', 
  //     answer: 'De tandarts richt zich vooral op het herstellen van het gebit. De mondhygiënist voorkomt aandoeningen en problemen in de mond. Je wordt doorverwezen als er uitgebreidere behandelingen nodig zijn zoals een vulling, wortelkanaalbehandeling of een extractie.', 
  //     key: '3' 
  //   },
  //   { 
  //     question: 'Hoe lang duurt een behandeling?', 
  //     answer: 'Een behandeling duurt meestal tussen de 30 en 45 minuten. ', 
  //     key: '4' 
  //   },
  //   { 
  //     question: 'Hoe vaak moet je naar een mondhygiënist?', 
  //     answer: 'Het is belangrijk om regelmatig te komen, 2 tot 4 keer per jaar. ', 
  //     key: '5' 
  //   },
  //   { 
  //     question: 'Vanaf welke leeftijd ga je naar een mondhygiënist?', 
  //     answer: 'Vanaf 2 jaar oud. Op een spelenderwijs laten wij kinderen wennen aan behandelingen, geven poetsinstructies en voedingsadviezen om gaatjes te voorkomen.', 
  //     key: '6' 
  //   },
  //   { 
  //     question: 'Wordt een behandeling vergoed door de zorgverzekering?', 
  //     answer: 'De tandarts en mondhygiënist vallen niet onder de basis verzekering. Je moet dus aanvullend verzekerd zijn om de behandeling vergoed te krijgen. Bij kinderen tot 18 jaar wordt wel alles vergoed vanuit de basisverzekering!', 
  //     key: '7' 
  //   }
  // ]

  return (
    <section className="section-spacing" id="veelgestelde-vragen">
      <Container >
        <div className="faqs-wrapper">
        <div className="faq-text">
          <h2>{faqTitle}</h2>

        </div>
        <div className="faq-items">
          <Accordion defaultActiveKey={faqs[0].strapi_id}>
          {faqs.map((item) => (
              <Accordion.Item eventKey={item.strapi_id}>
                <Accordion.Header as='div'><p>{item.question}</p></Accordion.Header>
                <Accordion.Body>
                  <ReactMarkDown>{item.answer.data.answer}</ReactMarkDown>
                </Accordion.Body>
              </Accordion.Item>
            ))}
          </Accordion>
        </div>
        </div>
      </Container>
    </section>
  );
}

export default Faq;
