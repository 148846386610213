import { ReactMarkdown } from 'react-markdown/lib/react-markdown';
import CalendlyButton from './calendly-popup';
/* eslint-disable-next-line */
export interface HeroProps { }

export function Hero({
  content
}) {
  return (
    <div className="hero-background">
      <div className='hero-wrapper'>
        <div className='hero-content'>
          <h1 className='pre-header'>{content.subtitle} </h1>
          <p className='header'>{content.title}</p>
          <ReactMarkdown>
            {content.body.data.body}
          </ReactMarkdown>
          <CalendlyButton title='Afspraak Maken'/>
        </div>
        <div className='hero-image'>
          <img src={content.image.url} alt='Ontspannen Glimlach' className='img-fluid' />
        </div>
      </div>
    </div>
  );
}

export default Hero;
