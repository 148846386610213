import React from 'react';
import { Icon } from "leaflet";
import "leaflet-gesture-handling";
import "leaflet-gesture-handling/dist/leaflet-gesture-handling.css";
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet'
import Logo from "../images/locationCircle.png"

const position = [52.36241065349475, 4.911193411330186]
const markerPosition = [52.36262468940759, 4.910902449011977]

const LocationMap = () => {
  if (typeof window !== 'undefined') {
    const customIcon = new Icon({
      iconUrl: Logo,
    });
    return (
      <MapContainer center={position} zoom={14} gestureHandling='true' className='contact-map-container'>
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        <Marker position={markerPosition} icon={(!!customIcon ? customIcon : null)}>
          <Popup>
            <p>Roetersstraat 41 <br></br>
            1018WB Amsterdam
            </p>
          </Popup>
        </Marker>
      </MapContainer>
    )
  }
  return (<p>Geen image</p>)
}


export default LocationMap