import Eline from "../images/eline.webp"
import Logo from "../images/about-logo.png"
import Container from "react-bootstrap/esm/Container";
import { ReactMarkdown } from "react-markdown/lib/react-markdown";
/* eslint-disable-next-line */
export interface AboutProps { }

export function About({ content }) {
  return (
    <section className="section-spacing" id='over-mij'>
      <Container>
      <div className='about-wrapper'>
        <div className='about-content'>
          <h2>{content.title}</h2>
          <ReactMarkdown>{content.body.data.body}</ReactMarkdown>
        </div>
        <div className='eline'>
          <img src={content.image.url} alt={content.image.alternativeText} title={content.image.caption} />
        </div>
        <div className="about-logo">
          <img src={Logo} alt='Logo Mondzorg Sarphaat' style={{opacity:0}} className="img-fluid" />
        </div>
      </div>
    </Container>
    </section>
  );
}

export default About;
