import React from 'react'
import Treatments from '../components/treatments'
import { graphql, useStaticQuery } from 'gatsby'
import Layout from '../components/layout'

const TreatmentsPage = () => {
    const data = useStaticQuery(graphql`
    query {
      strapiHomepage {
        treatments {
          title
          body {
            data {
              body
            }
          }
        }
      }
    }
    `)
  return (
    <Layout>
        <Treatments content={data.strapiHomepage.treatments} sliceRange={99} homepage={false} />
    </Layout>
  )
}

export default TreatmentsPage