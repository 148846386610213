import React from "react";
import { PopupModal } from "react-calendly";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'

library.add(fas)


class CalendlyButton extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
    };
  }

  render() {
    if (typeof window !== 'undefined') {
      return (
        <div id="root">
          <button
            onClick={() => this.setState({ isOpen: true })}
            className='btn-primary hide-mobile'
          >
            {this.props.title || <FontAwesomeIcon icon="calendar" />}
          </button>
          <a href="https://calendly.com/sarphaat-22" className="hide-desktop">
            <button className="btn-primary">
              {this.props.title || <FontAwesomeIcon icon="calendar" />}
            </button>
          </a>
          <PopupModal
            url="https://calendly.com/sarphaat-22"
            onModalClose={() => this.setState({ isOpen: false })}
            open={this.state.isOpen}
            rootElement={document.getElementById("root")}
          />
        </div>
      );
    }
    return null
  }
}

export default CalendlyButton