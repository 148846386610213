import React from 'react';
import Container from 'react-bootstrap/esm/Container';
import { ReactMarkdown } from 'react-markdown/lib/react-markdown';
import Faq from '../components/faq';
import Layout from '../components/layout';
import { useStaticQuery, graphql } from 'gatsby';

interface Person {
  image: {
    url: string;
    alternativeText: string;
    caption: string;
  };
  body: string;
  email: string;
  phone: string;
  big: string;
  name: string;
  strapi_id: string;
}

const Office = () => {
  const data = useStaticQuery(graphql`
    query {
      strapiOffice {
        title
        body {
          data {
            body
          }
        }
        images {
          url
          alternativeText
          caption
        }
        persons {
          image {
            url
            caption
            alternativeText
          }
          body
          email
          phone
          big
          strapi_id
        }
        faqs {
          question
          answer {
            data {
              answer
            }
          }
        }
        faqTitle
      }
    }
  `);

  const { title, persons, images, body, faqTitle, faqs } = data.strapiOffice;

  return (
    <Layout>
      <section>
        <Container>
          <div className="image">
            <img
              src={images[0].url}
              alt={images[0].alternativeText}
              style={{ maxWidth: '100%' }}
            />
          </div>
          <div className="body">
            <h1>{title}</h1>
            <ReactMarkdown>{body.data.body}</ReactMarkdown>
          </div>
        </Container>
      </section>
      <section>
        <Container style={{ marginTop: 100 }}>
          <div className="persons-wrapper">
            {persons.map((person: Person) => (
              <div className="person-wrapper" key={person.strapi_id}>
                <img
                  src={person.image.url}
                  alt={person.image.alternativeText}
                  className="person-image"
                />
                <div>
                  <h3>{person.name}</h3>
                  <p>{person.body}</p>
                  <span style={{ fontWeight: 600 }}>E-mail:</span>{' '}
                  <a href={`mailto:${person.email}`}>{' '}{person.email}</a>{' '}
                  <br></br>
                  <span style={{ fontWeight: 600 }}>Telefoon:</span>
                  <a href={`tel:${person.phone}`}>{' '}{person.phone}</a> <br></br>
                  <span style={{ fontWeight: 600 }}>BIG:</span> {' '}{person.big}
                </div>
              </div>
            ))}
          </div>
        </Container>
      </section>
      {faqs && (
        <section>
          <Container>
            <Faq faqTitle={faqTitle} faqs={faqs} />
          </Container>
        </section>
      )}
    </Layout>
  );
};

export default Office;
