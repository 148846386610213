import LocationMap from './map';
import LinkedIn from '../images/Linkeidn.svg';
import Instagram from '../images/Instagram.svg';
import { ReactMarkdown } from 'react-markdown/lib/react-markdown';
import { useStaticQuery, graphql } from 'gatsby';
import Container from 'react-bootstrap/esm/Container';

/* eslint-disable-next-line */
export interface ContactProps {}

export function Contact() {
  const data = useStaticQuery(graphql`
    query {
      strapiHomepage {
        contact {
          body {
            data {
              body
            }
          }
          title
          titleOpeningHours
          location {
            street
            zip
            phone
            building
            email
          }
          opening {
            openinghours
            day
          }
        }
      }
    }
  `);

  const content = data.strapiHomepage.contact;

  return (
    <div id="locatie-contact">
      <Container>
      <div className="row-50">
        <div className='contact-content'>
          <h2>{content.title}</h2>
          <ReactMarkdown>{content.body.data.body}</ReactMarkdown>
          <p>
            {content.location.street} <br></br>
            {content.location.building} <br></br>
            {content.location.zip} <br></br>
            <a href="tel:0619419752">{content.location.phone}</a>
            <br></br>
            <a href="mailto:info@mondzorgsarphaat.nl" style={{ margin: 0 }}>
              {content.location.email}
            </a>
          </p>
          <p>
            <span style={{ fontWeight: 600 }}>
              {' '}
              {content.titleOpeningHours}:
            </span>{' '}
            <br></br>
            {content.opening.map((item) => (
              <span key={item.strapi_id}>
                {item.day} {item.openinghours} <br></br>
              </span>
            ))}
          </p>
          {/* <img src={Facebook} alt='facebook'></img> */}
          <a
            href="https://www.instagram.com/mondzorg_sarphaat/"
            style={{ marginRight: 12 }}
          >
            <img src={Instagram} alt="Instagram"></img>
          </a>
          <a href="https://www.linkedin.com/in/eline-van-den-berg-a8b621108 ">
            <img src={LinkedIn} alt="LinkedIn"></img>
          </a>
          {/* <img src={Twitter} alt='Twitter'></img> */}
        </div>

        <div className="contact-map">
          <LocationMap></LocationMap>
        </div>
      </div>
      </Container>
    </div>
  );
}

export default Contact;
