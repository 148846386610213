import React from "react";
import Container from 'react-bootstrap/Container'
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import Logo from "../images/logo.png"
import Button from "react-bootstrap/Button"
import CalendlyButton from "./calendly-popup";
import { Link } from "gatsby";

const Header = () => (
    <Navbar collapseOnSelect expand="lg" bg="white" style={{ height: 105 }}>
        <Container>
            <Link to='/' className="navbar-brand"><img src={Logo} alt="Mondzorg Sarphaat Logo" title="Welkom bij Mondzorg Sarphaat"></img></Link>
            <div className="temp-nav">
                <Nav.Link href="/behandelingen">Behandelingen</Nav.Link>
                <Link to='/de-praktijk' className="nav-link">De Praktijk</Link>
                <Link to='/locatie-en-contact' className="nav-link">Locatie & Contact</Link>
                {/* <Nav.Link href="#veelgestelde-vragen">Tarieven</Nav.Link> */}
               <CalendlyButton  title="Afspraak Maken" />
            </div>
        </Container>
    </Navbar>
)

export default Header